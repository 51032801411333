import CustomErrorPage from "@components/Pages/ErrorPage/CustomErrorPage"
import {NextPageContext} from "next"

interface ErrorProps {
	statusCode: number
}

const ErrorPage: React.FC<ErrorProps> = ({statusCode}) => {
	return <CustomErrorPage statusCode={statusCode} />
}

// @ts-expect-error
ErrorPage.getInitialProps = ({res, err}: NextPageContext) => {
	const statusCode = res?.statusCode
		? res.statusCode
		: err?.statusCode
		? err.statusCode
		: 404
	return {statusCode}
}

export default ErrorPage
